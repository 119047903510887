<template>
  <div class="card">
    <div class="card-header" :class="data.color">
      {{ data.title }}
      <i
        class="fa fa-info-circle"
        data-toggle="tooltip"
        data-placement="top"
        :title="data.title"
        aria-hidden="true"
      ></i>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <span v-if="data.prefix != ''">{{ data.prefix }}</span>
        <h5 class="ml-2">{{ parseFloat(data.count).toLocaleString() }}</h5>

        <span v-if="data.suffix != ''">{{ data.suffix }}</span>

        <span class="count" v-if="data.inv_status"
          >{{ data.inc }}%
          <i
            class="fa "
            :class="[data.rise ? 'fa-caret-up' : 'fa-caret-down i-down']"
            aria-hidden="true"
          ></i>
        </span>
      </div>
      <div>
        <span class="date">
          <span class="span-1" v-if="data.date.from != ''">{{
            data.date.from
          }}</span>
          <span class="ml-1" v-if="data.date.to != ''">-</span>
          <span class="ml-1" v-if="data.date.to != ''">{{ data.date.to }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  props: {
    data: {
      type: Object,
      default: null
    }
  }
};
</script>
<style lang="scss" scoped>
.dash {
  .card {
    .card-header {
      i {
        position: absolute;
        top: 10%;
        left: 90%;
        color: white;
      }
      color: white;
      font-weight: 700;
      padding: 1rem 2.25rem;
      font-size: 11px;
    }
    .header-red {
      background-color: #dc3545;
    }
    .header-blue {
      background-color: #007bff;
    }
    .header-green {
      background-color: #28a745;
    }
    .header-yellow {
      background-color: #ffc107;
    }

    .card-body {
      height: 10%;
      .count {
        position: absolute;
        top: 45%;
        left: 58%;
        i {
          position: absolute;
          top: 90%;
          left: 20%;
          color: #83db83;
        }
        .i-down {
          color: red !important;
        }
      }
      .date {
        margin-top: 8%;
        opacity: 0.5;
      }
      .suffix {
        position: absolute;
        top: 48%;
        left: 19%;
      }
      .prefix {
        position: absolute;
        top: 50%;
        left: 7%;
      }
      .span-2 {
        margin-left: 6%;
      }
      .span-mid {
        position: absolute;
        top: 68%;
        left: 46%;
      }
    }
  }
}
</style>

<style>
.daterangepicker .calendars-container {
  margin-left: 20% !important;
  margin-top: -43% !important;
}
.brand {
  height: 47.5px !important;
  width: 90% !important;
  background: #eef0f8 !important;
  top: -48.5px !important;
}
</style>
<style lang="scss" scoped>
.graph {
  margin-top: 2%;
  .graph-left {
    .graph-left-text {
      position: absolute;
      top: 0%;
      left: 7%;
    }
  }
  img {
    width: 39%;
  }
  .graph-mid {
    margin-left: 14%;
  }
  .card {
    width: 100%;
    margin-top: 1%;
  }
}
.text-color-blue {
  color: #007bff;
}
.vue-daterange-picker {
  margin-top: 4%;
  margin-left: 26%;
}

.btn-active {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}
.card-header {
  background-color: #3699ff;
  color: #ffff !important;
}
.dash {
  .card {
    .card-header {
      padding: 1rem 2.25rem;
    }
  }
}
.form-check-flex {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.appointment-list {
  width: 100%;
  height: 100%;
  .opacityadd {
    opacity: 1 !important;
  }
  input {
    display: none;
    &:checked + label {
      background: #eee;
    }
  }
  label {
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    width: 50%;
    text-align: center;
    background-color: #007bff !important;
    color: #ffff;
    position: relative;
    transition: 0.25s background ease;
    cursor: pointer;
  }
  .line {
    position: absolute;
    height: 4px;
    background: #ffc107;
    width: 50%;
    top: 5%;
    left: 0;
    transition: 0.25s ease;
  }
  .scrollable::-webkit-scrollbar {
    width: 1em;
  }

  .scrollable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .scrollable::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0px solid slategrey;
  }
  .content-container {
    background: #ffff;
    // position: absolute;
    // height: 60%;
    font-size: 12px;
    // overflow-y: scroll;
    // width: 73%;
    margin-left: 0%;
    .scrollable {
      overflow-y: scroll;
      height: 500px;
    }
    .scroll1 {
      width: 43%;
    }
    .scroll2 {
      width: 43%;
    }

    .content {
      padding: 10px;
      h3 {
        font-weight: 200;
        margin: 10px 0;
      }
      p {
        margin: 10px 0;
      }
      p,
      i {
        font-size: 13px;
      }
    }
  }
}
</style>
<style scoped>
.spinner-3 {
  margin-left: 35%;
}
</style>
<template>
  <!-- <h1>Dashboard</h1> -->
  <div class="dash">
    <div class="row">
      <div
        class="col-lg-3 col-sm-2 mb-3"
        v-for="(b, i) in getDashboardcardDate"
        :key="i"
      >
        <dashboard-card :data="b" :key="i"></dashboard-card>
      </div>
    </div>
    <div class="appointment-list">
      <label for="tab1">Today's Appointment</label>

      <label for="tab2">Upcoming Appointment</label>

      <div class="content-container">
        <div class="row ">
          <div class="content opacityadd col-md-6 scrollable scroll1">
            <appointment-list
              :key="key"
              :app-type="false"
              :dash-type="'today'"
            ></appointment-list>
          </div>
          <div class="content opacityadd col-md-6 scrollable scroll2">
            <appointment-list
              :key="key"
              :app-type="false"
              :dash-type="'upcomming'"
            ></appointment-list>
          </div>
        </div>
      </div>
    </div>
    <div class="row graph">
      <!-- <div class="col-md-3 graph-left"> -->
      <!-- <img src="/media/svg/filter-icon.svg" /> -->
      <!-- <div class="graph-left-text">
          <h1>Filter</h1>
          <p class="letf-text-1">
            Set data filter for period to the insights below
          </p> -->
      <!-- <p class="text-color-blue">Set default</p> -->
      <!-- </div>
      </div> -->
      <!-- <div class="col-md-6">
        <div
          class="btn-group graph-mid"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 1 ? 'btn-active' : '']"
            @click="getseriesdata(1)"
          >
            Day
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 2 ? 'btn-active' : '']"
            @click="getseriesdata(2)"
          >
            Week
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 3 ? 'btn-active' : '']"
            @click="getseriesdata(3)"
          >
            Month
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 4 ? 'btn-active' : '']"
            @click="getseriesdata(4)"
          >
            Year
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="opendatepicker"
            :class="[selectedBtn == 5 ? 'btn-active' : '']"
          >
            Date Range
          </button>
        </div>
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :opens="'center'"
          @update="getseriesdata(5)"
        ></date-range-picker>
      </div> -->
      <!-- <div class="col-md-3">
        <div class="graph-right">
          <p class="text-color-blue">Filter applied</p>
          <p>Filter applied to the insights below.</p>
          <p>Period 05/01/2022 - 05/01/2022</p>
          <span
            >* If client on September 1 creates as booking on September 10 then
            September 1 is record date and September 10 is Booking date</span
          >
        </div>
      </div> -->
      <div class="btn-group" role="group" aria-label="Basic example">
        <button
          type="button"
          class="btn btn-outline-primary"
          :class="[selectedBtn == 1 ? 'btn-active' : '']"
          @click="getseriesdata(1)"
        >
          last Week
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          :class="[selectedBtn == 2 ? 'btn-active' : '']"
          @click="getseriesdata(2)"
        >
          last Month
        </button>
      </div>
      <div class="card">
        <div class="card-header">Total Appointments</div>
        <div class="card-body">
          <div class="row">
            <!-- <div class="col-md-3 col-lg-3 col-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="total"
                  v-model="app_radio"
                  @change="getseriesdata(selectedBtn)"
                />
                <label class="form-check-label" for="inlineRadio1">Total</label>
              </div>
              <div
                class="form-check form-check-inline"
                v-if="!this.currentUser.u.is_client"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="location"
                  v-model="app_radio"
                  @change="getseriesdata(selectedBtn)"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >By Location</label
                >
              </div>
              <div
                class="form-check form-check-flex"
                v-for="(s, i) in series"
                :key="i"
                v-show="app_radio == 'location'"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="s.name"
                  :id="s.name"
                  v-model="selected_location"
                />
                <label class="form-check-label" :for="s.name">
                  {{ s.name }}
                </label>
                <br />
              </div>
            </div> -->
            <div class="col-md-12">
              <v-data-loader v-if="getLoadingData"></v-data-loader>

              <line-chart
                :key="index"
                :width="900"
                :ctype="'bar'"
                :options="options"
                :series="filtered_location"
                v-else
              ></line-chart>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card"
        v-if="currentUser.u.is_service_provider || currentUser.u.is_admin"
      >
        <div class="card-header" v-if="currentUser.u.is_service_provider">
          Location
        </div>
        <div class="card-header" v-if="currentUser.u.is_admin">
          Service Providers
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <v-data-loader v-if="getLoadingData"></v-data-loader>

              <line-chart
                :key="index"
                :width="900"
                :ctype="'line'"
                :options="lineoptions"
                :series="lineseries"
                v-else
              ></line-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="!currentUser.u.is_admin">
        <div class="card-header">Service</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <v-data-loader v-if="getLoadingData"></v-data-loader>

              <line-chart
                :key="index"
                :width="900"
                :ctype="'line'"
                :options="serviceoptions"
                :series="serviceseries"
                v-else
              ></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <h1>Dashboard</h1>
  </div> -->
</template>

<script>
// import DateRangePicker from "vue2-daterange-picker";
// import BarChart from "@/includes/graph/Chart";
import LineChart from "@/includes/graph/Chart";

import DashboardCard from "@/components/Dashboard/DashboardCard";
import vDataLoader from "@/components/frontend/spinner.vue";
import AppointmentList from "@/pages/backend/appointment/lists/index";

import { mapGetters } from "vuex";
import {
  GET_DASHBOARD_CARD_DATA,
  FETCH_DATA_FOR_GRAPH
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  components: {
    // DateRangePicker,
    LineChart,
    DashboardCard,
    vDataLoader,
    AppointmentList
  },
  data() {
    let startDate = this.$moment().format("YYYY/MM/DD");
    let endDate = this.$moment().format("YYYY/MM/DD");

    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: ""
        }
      ],
      app_list: "tab1",
      key: 0,
      getLoadingData: false,
      getLoadingDataLine: false,
      getLoadingDataServiceLine: false,
      datacollection: null,
      format: { format: "dd/mm/yyyy" },
      dateRange: { startDate, endDate },
      lineoptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          }
        },
        colors: [],

        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 3
        },

        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date"
          }
        },

        legend: {
          position: "bottom",
          horizontalAlign: "right"
        }
      },
      options: {
        chart: {
          height: 350,
          type: "bar",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          }
        },
        colors: ["#3699ff"],

        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 3
        },

        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date"
          }
        },

        legend: {
          position: "bottom",
          horizontalAlign: "right"
        }
      },
      series: [],
      lseries: [],

      lineseries: [],
      selectedBtn: 2,
      app_radio: "total",
      selected_location: [
        "Carolina Fingerprinting - postal",
        "Carolina Fingerprinting - unique"
      ],
      index: 0,
      lindex: 0,
      sindex: 0,
      serviceoptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },

          zoom: {
            enabled: false
          }
        },
        colors: [],

        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 3
        },

        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date"
          }
        },

        legend: {
          position: "bottom",
          horizontalAlign: "right"
        }
      },
      serviceseries: []
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getDashboardcardDate"]),
    filtered_location: function() {
      var series = this.series;
      let location = [...series];
      if (this.app_radio == "total") {
        return location;
      } else {
        const result = location.filter(loc => {
          if (this.selected_location.includes(loc.name)) {
            return loc;
          }
        });
        return result;
      }
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    let data = {
      type: this.currentUser.u.is_admin
        ? "admin"
        : this.currentUser.u.is_client
        ? "c"
        : "sp",
      booking: { booking: this.booking }
    };
    this.$store.dispatch(GET_DASHBOARD_CARD_DATA, data);

    this.getseriesdata();
  },
  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    opendatepicker() {
      this.$refs.picker.togglePicker();
    },
    setdate(val) {
      let startDate = new Date();
      let endDate = new Date();
      switch (val) {
        case 5:
          startDate.setDate(startDate.getDate());
          this.format = { format: "dd/mm/yyyy" };
          this.dateRange = { startDate, endDate };
          break;
        case 1:
          this.format = { format: "dd/mm/yyyy" };
          startDate = this.$moment(new Date()).add(-6, "days");
          endDate = this.$moment(new Date());
          this.dateRange = { startDate, endDate };
          break;
        case 2:
          this.format = { format: "dd/mm/yyyy" };
          startDate = this.$moment(new Date()).add(-30, "days");
          endDate = this.$moment(new Date());
          this.dateRange = { startDate, endDate };
          break;
        case 4:
          this.format = { format: "yyyy" };
          startDate = this.$moment().startOf("year");
          endDate = this.$moment().endOf("year");
          this.dateRange = { startDate, endDate };
          break;
        default:
          break;
      }
    },
    getseriesdata(val = 1) {
      this.getseriesdatatotal(val);
      // this.getseriesdataloaction(val);
    },
    tail(arr, ind) {
      let mhs, lhs;
      if (arr.length / 2 > ind) {
        mhs = arr.length - 1 - ind;
        lhs = ind;
      } else {
        mhs = ind;
        lhs = arr.length - 1 - ind;
      }
      let nd = [arr[ind]];
      for (let i = 0; i < lhs; i++) {
        nd.push(arr[ind + i + 1]);
        nd.push(arr[ind - i - 1]);
      }
      for (let i = 0; i < mhs - lhs; i++) {
        nd.push(arr[i]);
      }
      return nd;
    },

    dense(len, den) {
      let st = Math.ceil(len / den);
      let nd = [];
      for (let i = 0; i < st; i++) {
        for (let j = 0; j < den; j++) {
          nd.push(st - i);
        }
      }
      if (len % 2 !== 0) {
        nd.shift();
      }
      return nd;
    },
    shift_weight(arr, ind, de) {
      let ta = this.tail(arr, ind);
      let nd = [];
      let den = this.dense(arr.length, de);
      for (let i = 0; i < ta.length; i++) {
        for (let j = 0; j < den[i]; j++) {
          nd.push(ta[i]);
        }
      }
      return nd;
    },
    randomColor() {
      let hexcode = "09857623412abcdef";
      let ocean = this.shift_weight(
        Array.from({ length: 16 }, (x, i) => hexcode[i]),
        0,
        5
      );
      return (
        "#" +
        Array.from({ length: 6 })
          .map(() => ocean[Math.floor(Math.random(0, 1000) * ocean.length)])
          .join("")
      );
      // return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },

    getseriesdatatotal(val = 1) {
      this.selectedBtn = val;
      this.setdate(val);
      this.options.xaxis.categories = [];
      this.series = [];
      this.getLoadingData = true;

      let data = {
        daterange: this.dateRange,
        type: val,
        client: this.currentUser.u.is_admin
          ? "admin"
          : this.currentUser.u.is_client
          ? "c"
          : "sp"
      };
      this.$store
        .dispatch(FETCH_DATA_FOR_GRAPH, data)
        .then(res => {
          let [location] = res.details;
          this.options.xaxis.categories = [];
          this.options.xaxis.categories = location.location_total_detail.date;
          this.series = [
            {
              name: "Total",
              data: location.location_total_detail.count
            }
          ];
          if (location.location_wise_detail) {
            this.lineoptions.colors = [];
            this.lineoptions.xaxis.categories = [];
            this.lineoptions.xaxis.categories =
              location.location_total_detail.date;

            this.lineoptions.colors =
              location.location_wise_detail.location_colors;

            this.lineseries = location.location_wise_detail.detail;
          }
          this.serviceseries = location.service_details.detail;
          this.serviceoptions.xaxis.categories = [];
          this.serviceoptions.xaxis.categories = location.service_details.date;

          this.serviceoptions.colors = location.service_details.service_colors;
          this.index += 1;

          this.getLoadingData = false;
        })
        .catch(() => {
          this.$toastr.e("oops! something went wrong");
          this.getLoadingData = false;
          this.index += 1;
        });
    }
  }
  // destroyed() {
  //   var chart = new VueApexCharts(el, options);
  //   chart.destroy();
  // }
};
</script>
